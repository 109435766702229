import { setupTranslations } from 'hooks/useTranslation';
import translationFile from 'i18n/en';
import { SearchSettingsContext } from 'context';
import { useSearchStore } from 'store';
import React, { useEffect } from 'react';
import 'intersection-observer';
import Wrapper from '@moonshineragency/ui/src/components/Wrapper/Wrapper';
import { navigate } from 'gatsby';

import { unstable_useFormState as useFormState } from 'reakit/Form';

setupTranslations(translationFile);

const WrappedPage = ({ children }) => {
  const store = useSearchStore();

  const { formValues } = store;

  const form = useFormState({
    values: {
      ...formValues,
    },
    onValidate: values => {
      const errors = {};

      if (values?.injuryLevel?.length > 2) {
        errors.injuryLevel = 'Please specify your injury level';
      }
      if (Object.keys(errors).length) {
        throw errors;
      }
    },
    onSubmit: () => {
      navigate('/trials');
    },
  });

  const valuesFromForm = form.values;
  const dispatchSearch = () => {
    store.setFormValues(valuesFromForm);
  };
  useEffect(dispatchSearch, [valuesFromForm]);

  return (
    <SearchSettingsContext.Provider value={{ form }}>
      <Wrapper>{children}</Wrapper>
    </SearchSettingsContext.Provider>
  );
};
export default WrappedPage;
