let MESSAGES = null;

export const setupTranslations = path => {
  MESSAGES = path;
};

const dotToObject = (key, obj) => key.split('.').reduce((o, i) => o[i], obj);

const useTranslation = (namespace = null) => {
  if (!MESSAGES) {
    // eslint-disable-next-line no-console
    console.error(
      'Translation Object is not set - please define translations via setupTranslations',
    );
    MESSAGES = {};
  }

  const t = key => {
    return dotToObject(`${namespace}.${key}`, MESSAGES);
  };

  return { t };
};

export default useTranslation;
