import create from 'zustand';
import { persist } from 'zustand/middleware';

export const useStore = create(
  persist(
    set => ({
      user: null,
      setUser: user => set({ user }),
      removeUser: () => set({ user: null }),
    }),
    {
      name: 'user',
    },
  ),
);
export const initialFormValues = {
  filters_counter: true,
  is_recruiting_now_soon: false,
  include_uncurated: false,
  location_name: null,
  location: null,
  sort_by: 'distance',
  unit: 'km',
  distance: {
    distance_from: null,
  },
  severity: null,
  injuryLevel: null,
  time_since_injury: null,
  trial_type: null,
};
export const useSearchStore = create(
  persist(
    set => ({
      formValues: {
        ...initialFormValues,
      },
      setFormValues: formValues => set({ formValues }),
    }),
    {
      name: 'search-form-storage',
      getStorage: () => sessionStorage,
    },
  ),
);

export const usePermissionsStore = create(
  persist(
    set => ({
      permissions: {},
      setPermissions: permissions => set({ permissions }),
    }),
    {
      name: 'permissions-storage',
    },
  ),
);

export const useTableFilter = create(
  persist(
    set => ({
      tableFilter: null,
      setTableFilter: tableFilter => set({ tableFilter }),
    }),
    {
      name: 'table-filter',
    },
  ),
);

export const useNotificationBarStore = create(
  persist(
    set => ({
      visibility: true,
      setVisibility: visibility => set({ visibility }),
    }),
    {
      name: 'notification-bar-storage',
    },
  ),
);

export default useStore;
